.App {
  text-align: center;
  font-family: "EB Garamond", serif;
  color: #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.cutive-regular {
    font-family: "Cutive", serif;
    font-weight: 400;
    font-style: normal;
}

.cutive-mono-regular {
    font-family: "Cutive Mono", monospace;
    font-weight: 400;
    font-style: normal;
}
  

.split-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar{
    height: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
}

.navbar-logo{
    padding-left: 10px;
    cursor: pointer;
}

.scene {
    padding-top: 60px;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.standard-width {
    width: 90vw;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .standard-width {
        width: 60vw;
        max-width: 500px;
    }
}


.book {
    display: flex;
    gap: 1rem;
    text-align: left;
}

.book img {
    width: 100px;
    height: auto;
    object-fit: contain; /* or 'cover' depending on your need */
}

.book-info {
    gap: 0rem;
    
}

.book-info h2 {
    font-size: 1.5rem;
    font-weight: 300;
    padding: 0;
    margin: 0;
}

.book-info h3 {
    font-size: 1rem;
    color: #666;
    padding: 0;
    margin: 0;
}

.book-info p {
    font-size: 1rem;
    padding: 0;
    padding-top: 10px;
    margin: 0;
}

.books {
    gap: 40px;
    display: grid;
}

.book-links{
    display: flex;
    gap: 30%;
    justify-content: center;
}


.link-card {
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-card img {
    width: 60px;
    border-radius: 10px;
    box-sizing: border-box;
}

.book-container {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    padding: 10px;
}

.book-links-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(174, 174, 174, 0.8);
    z-index: 500;
}

.book-links-container.show {
    opacity: 1;
}


/* Add this CSS to your stylesheet */

/* Style for the draggable book container */
.draggable-book {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s; /* Smooth transition for background color */
}

/* Style for the drag handle */
.drag-handle {
    cursor: grab;
    margin-right: 20px;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Change cursor on active state */
.drag-handle:active {
    cursor: grabbing;
}

/* Highlight the draggable book container when the drag handle is hovered */
.draggable-book.hovered {
    background-color: lightblue; /* Adjust as needed */
}

/* Style for the currently dragged book */
.draggable-book.dragging {
    opacity: 0.5; /* Adjust as needed */
}

.search-results{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 100px;
    left: 0;
    position: fixed;
    z-index: 999;
}


.search-results-overlay{
    position: fixed;
    top: 80px;
    width: 80%;
    height: 80%;
    background-color: white;
    z-index: 999;
    overflow-y: scroll;
    border-radius: 15px;
    z-index: 1000;
    padding: 10px;
}

.search-results-overlay::-webkit-scrollbar {
    width: 0px;
}

.modal-backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.add-book-button{
    color: #333;
    background-color: #e9ecef;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
}
e8ecef
.add-book-button:hover{
    background-color: #ced4da;
}

.icon{
    padding-right: 10px;
}

.search input{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ced4da;
    font-size: 16px;
    font-family: "Cutive Mono", monospace;
}

.search input:focus{
    outline: none;
}

.search-button{
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: 1px solid #ced4da;
    background-color: #e9ecef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-button:hover{
    background-color: #ced4da;
}

.search-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.delete-button{
    background-color: red;
    color: white;
    height: 150px;
    border: none;
    width: 150px;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.slide-in {
    animation: slideIn 0.1s ease-in-out;
}

.book img{
    box-shadow: 
        2px 2px 4px rgba(0, 0, 0, 0.1), /* Shadow for bottom and right side */
        -2px -2px 4px rgba(255, 255, 255, 0.7); /* Highlight for top and left side */
}

.shelf{
    overflow-y: auto;
    height: 65vh;
}

.shelf::-webkit-scrollbar {
    width: 0px;
}

.divider{
    border-bottom: 1px solid #ced4da;
    margin-bottom: 10px;
}

.divider-no-padding{
    border-bottom: 1px solid #ced4da;
}

.small-break{
    margin-top: 10px;
}


.btn{
    border-radius: 20px;
    padding: 10px;
    border: none;
    background-color: #e9ecef;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
}

.primary{
    background-color: #007bff;
    color: white;
}

.primary:hover{
    background-color: #0056b3;
}

.neutral{
    background-color: #e9ecef;
    color: #333;
}

.neutral:hover{
    background-color: #ced4da;
}

.brand {
    background-color: #333;
    color: white;
}

.brand:hover{
    background-color: #000;
}

.minimal{
    background-color: transparent;
    color: #333;
}
.minimal:hover{
    background-color: #e9ecef;
}

.centered-centered{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.landing {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.landing h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 3rem;
    margin-top: 3rem;
    font-family: "Cutive", serif;
}

.landing p {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.landing-button {
    background-color: #333;
    color: #fff;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    font-family: "Cutive", serif;

}

.landing-button:hover {
    background-color: #000;
}

.marketing-copy {
    padding: 2rem;
    font-size: 1.25rem;
}

.navbar-buttons{
    display: flex;
    gap: 10px;
    margin-right: 10px;
}

.background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background-attachment: fixed; /* Keep the image fixed in place */
}


.background-image img {
    height: 600px;
    width: 100%; /* Maintain aspect ratio while covering height */
    object-fit: cover; /* Ensure the image covers the container, clipping as necessary */
    opacity: 0.4;
    display: block; /* Remove any default inline spacing */
}
.copy-container{
    background-color: #fff;
    display: flex;
    width: 100vw;
}

.demo-container-container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.demo-container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.demo-container h2{
    font-size: 1rem;
    font-weight: 600;
    font-family: "Cutive", serif;
}

.marketing-copy h2{
    font-size: 1rem;
    font-weight: 400;
    font-family: "Cutive", serif;
}

.demo-box{
    width: 300px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 20px;

    height: auto;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Cutive mono", monospace;
}

.no-scroll-x{
    overflow-x: hidden;
}

.circle-with-number{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #333;
    color: white;
    font-size: 1.5rem;
    font-family: "Cutive", serif;
}

.numbered-header{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 350px;
}

.numbered-header h2{
    width: 60%;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 500px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.dropdown-item {
    border-top: 1px solid #ced4da;
    border-left: none;
    border-right: none;
    border-bottom: none;

    padding: 10px;
    cursor: pointer;
    background-color: white;

    font-family: "Cutive", serif;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
}

.copy-link-container {
    display: flex;
    align-items: center;
    width: 90%;
    background-color: #e8ecef;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 30px;

}
  
.link-input {
    padding: 4px;
    flex-grow: 1;
    text-overflow: ellipsis;
    border: none;
    font-size: 18px;
    background-color: #e8ecef;
    font-family: "Cutive Mono", monospace;
}

.link-input:focus {
    outline: none;
}

.link-no-fuss{
    outline: none;
    color: rgba(0,0,0,0);
    border: none;
    background-color: transparent;
}

a:active, a:focus {
    outline: none;
    color: inherit;
}

.help-text{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
}